<template>
    <section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <!-- Welcome Intro Start -->
                <div class="col-12 col-md-10">
                    <div class="welcome-intro text-center mt-0 py-5"> 
                       <h1 class="text-white">{{ mission1 }} <br>{{ mission2 }}</h1>
                        <p class="text-white my-4">{{ mission3 }}</p>
                        <!-- Buttons -->
                        <div class="button-group">
                            <a href="#" class="btn btn-bordered-white">{{ contactus }}</a>
                            <!-- <a href="#" class="btn btn-bordered-white d-none d-sm-inline-block">View Process</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { db } from '../../../src/firebase-config.js'

export default {
    data() {
        return {
            language: localStorage.getItem('language') || 'en',
            mission1: "",
            mission2: "",
            mission3:"",
            contactus:""
        }
    },
    mounted(){
        this.loadLocaleMessges();
        // this.changeLanguage();
    },
    methods:{
        changeLanguage(obj){
            this.language = obj.target.value
            localStorage.setItem('language', obj.target.value)
            this.loadLocaleMessges()
        },
        loadLocaleMessges() {
            console.log("here");
            const data = db.collection("Transaltion").doc(this.language.toUpperCase())
            data.get().then((doc) => {
                if (!doc.exists) return;
    
                this.mission1 = doc.data().home.mission1;
                this.mission2 = doc.data().home.mission2;
                this.mission3 = doc.data().home.mission3;
                this.contactus = doc.data().home.contactus;


            });
        }
    }
}

</script>

<style>

</style>