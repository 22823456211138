<template>
  <section>
    <div class="top">
      <div class="text">
        <h1>RAFCO</h1>
        <h2>Available on</h2>
      </div>
      <div class="container">
        <img src="https://rafcoleasing.com/img/logo.png" /><br /><br />
        <div class="pic">
          <a href="https://apps.apple.com/th/app/rafco/id1529562321"  target="_blank"
            ><img
              src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1603265309apple-en.png"
          /></a>
          <br>
          <a
            href="https://play.google.com/store/apps/details?id=com.rptn.rafco" target="_blank"
          >
            <img
              src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1603265309google-en.png" /></a
          ><br>
          <a href="https://appgallery.huawei.com/#/app/C103468929" target="_blank"
            ><img src="https://www.ais.th/appgallery/images/Explore.png"
          /></a>
        </div>
      </div>
    </div>
    
  </section>
</template>

<script>
export default {
  name: "linkStore",
};
</script>

<style scoped>
body{
  width: 100%;
}

.top {
  padding-top: 2%; 
  padding-left: 14%;
   height: 362px;
  background-color: #e81e25;
  /* float: center; */
   margin: -8px;
    /* position: relative; */
    
}
 


.text {
  text-align: center;
  padding-top: 1rem;
  padding-right: 15%;
}

.container {
  position: absolute;
  box-shadow: 0 4px 10px rgb(0, 0, 0, 0.26);
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  width: 75%;
  display: block;
  padding-bottom: 2rem;
  padding-top: 2rem;
  position: fixed
}

.container img {
  width: 120px;
  height: 120px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  
}
h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: rgb(255, 255, 255);
  font-size: 2.5rem;
  text-align: center;
  text-shadow:0 4px 10px rgb(0, 0, 0, 0.26);
}
h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.2rem;
  color: rgb(249, 232, 232);
  text-align: center;
}
.pic img {
  width: 180px;
  height: 55px;
}
</style>