import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'



Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(App),

}).$mount('#app')
export default{

}

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional



