import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// import { db } from '../src/firebase-config.js'
// function loadLocaleMessges() {
//   const data = db.collection("Transaltion").doc("EN")
//   data.get().then((doc) => {
//     if (!doc.exists) return;
    
//     console.log("ข้อมูล", doc.data().header.home);
//     console.log("ข้อมูล???", doc.data().home.mission1);
    
//     console.log(doc.data().header);
//     return doc.data();
//   })
// }

export default new VueI18n({
  // data() {
  //   return {
  //     data: {
  //       header: "header",
  //       home: ""
  //     }
  //   };
  // },
  // messages: loadLocaleMessges()
})
