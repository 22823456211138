<template>
    <section id="contact" class="contact-area bg-grey ptb_10">
        <div class="container">
            <div class="row justify-content-between align-items-center">
          
            
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>