<template>
    <section class="section content-area bg-grey ptb_150">
        <!-- Shape Top -->
        <div class="shape shape-top">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
            c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
            c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
            </svg>
        </div>
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-12 col-lg-6">
                    <!-- Content Inner -->
                    <div class="content-inner text-center">
                        <!-- Section Heading -->
                        <div class="section-heading text-center mb-3">
                            <h2>{{ rafco_header }}</h2>
                            <!-- <p class="d-none d-sm-block mt-4">Our mission is ensuring access to prompt and constant financial services for Cambodian families as well as individuals with the opportunity to provide our clients efficient promoting sustainable economics and convenient lifestyles.At the same time, we are here to establish</p> -->
                            <!-- <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p> -->
                        </div>
                        <!-- Content List -->
                        <ul class="content-list text-left">
                            <!-- Single Content List -->
                            <li class="single-content-list media py-2">
                                <div class="content-icon pr-4">
                                    <span class="color-1"><i class="fas fa-angle-double-right"></i></span>
                                </div>
                                <div class="content-text media-body">
                                    <span><b>{{ mission_header }}</b><br>{{ mission_1 }}</span>
                                </div>
                            </li>
                            <!-- Single Content List -->
                            <li class="single-content-list media py-2">
                                <div class="content-icon pr-4">
                                    <span class="color-1"><i class="fas fa-angle-double-right"></i></span>
                                </div>
                                <div class="content-text media-body">
                                    <span><b>{{ ourservice_header }}</b><br>{{ ourservice_1 }}</span>
                                </div>
                            </li>
                            <!-- Single Content List -->
                            <li class="single-content-list media py-2">
                                <div class="content-icon pr-4">
                                    <span class="color-1"><i class="fas fa-angle-double-right"></i></span>
                                </div>
                                <div class="content-text media-body">
                                    <span><b>{{ vision_header }}</b><br>{{ vision_1 }}</span>
                                </div>
                            </li>
                        </ul>
                        <!-- <a href="#" class="btn btn-bordered mt-4">Learn More</a> -->
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <!-- Service Thumb -->
                    <div class="service-thumb mx-auto pt-4 pt-lg-0">
                        <img src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1635482581mission1.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!-- Shape Bottom -->
        <div class="shape shape-bottom">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
    c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
    c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
            </svg>
        </div>
    </section>
</template>

<script>
import { db } from '../../../src/firebase-config.js'

export default {
    data() {
        return {
            language: localStorage.getItem('language') || 'en',
            rafco_header: "",
            mission_header: "",
            mission_1:"",
            ourservice_header:"",
            ourservice_1:"",
            vision_header:"",
            vision_1:""
        }
    },
    mounted(){
        this.loadLocaleMessges();
        // this.changeLanguage();
    },
    methods:{
        changeLanguage(obj){
            this.language = obj.target.value
            localStorage.setItem('language',obj.target.value)
            this.loadLocaleMessges()
        },
        loadLocaleMessges() {
           
            const data = db.collection("Transaltion").doc(this.language.toUpperCase())
            data.get().then((doc) => {
                if (!doc.exists) return;
    
                this.rafco_header = doc.data().home.rafco_header;
                this.mission_header = doc.data().home.mission_header;
                this.mission_1 = doc.data().home.mission_1;
                this.ourservice_header = doc.data().home.ourservice_header;
                this.ourservice_1 = doc.data().home.ourservice_1;
                this.vision_header = doc.data().home.vision_header;
                this.vision_1 = doc.data().home.vision_1;
           
        
            });
        }
    }
}

</script>

<style>

</style>