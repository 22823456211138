<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import {db} from '../src/firebase-config.js'
export default {
  // name: "App",
  //  data()  {
  //       return {
  //         data: {
  //           home:''
  //         }
  //       };
  //   },
  //  async mounted(){
  //   const data = db.collection("Transaltion").doc("EN")
  //   await data.get().then((doc)=>{
  //     if(!doc.exists) return;
  //     console.log("ข้อมูล!!!!!",doc.data().home.contactus);
  //   })
  //   return data
    // await db.collection('Transaltion').get().then((querySnapshot) => {
    //   const data = querySnapshot.docs("EN").map((doc) => ({
    //   id: doc.id,
    //   ...doc.data(),
    // }));
    // console.log("All data in 'books' collection", data); 
    // });
  
// }
}
  
</script>

<style>

</style>