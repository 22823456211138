<template>
    <section class="section our-goal ptb_100" style="margin-top:100px">
        <br>
        <br>
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-12 col-lg-6">
                    <!-- Goal Content -->
                    <div class="goal-content section-heading text-center text-lg-left pr-md-4 mb-0">
                        <!-- <h2 class="mb-3">"RAFCO"</h2> -->
                        <p>{{ header_1 }}</p>
                        <p>{{ line_2 }}</p><br>
                        <p>{{ mission_1 }}</p>
                        <p>{{ mission_2 }}</p><br>

                        <p>{{ our_services }}</p>
                        <p>{{ our_services_1 }}</p><br>

                        <p>{{ vision }}</p>
                        <p>{{ vision_1 }}</p><br>

                        <p>{{ report }}</p>
                        <a style="color: blue;font-size: 18px;" :href=link_en >{{ name_en }}</a><br>
                        <a style="color: blue;font-size: 18px;" :href=link_kh >{{ name_kh }}</a>
                        <!-- <a style="color: blue;  font-size: 18px;"
                            href="https://linebotkeep-file.s3.ap-southeast-1.amazonaws.com/template_up/1672381426.pdf"
                            target="_blank"> ANNUAL REPORT 2021</a><br> -->
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <!-- Goal Thumb -->
                    <div class="goal-thumb mt-5 mt-lg-0">
                        <img src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1635739713aboutus1.jpg"
                            alt="">
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import { db } from '../../../src/firebase-config.js'

export default {
    data() {
        return {
            language: localStorage.getItem('language') || 'en',
            header_1: "",
            line_2: "",
            mission_1: "",
            mission_2: "",
            our_services: "",
            our_services_1: "",
            vision: "",
            vision_1: "",
            report: "",
            link_en: "",
            name_en: "",
            link_kh: "",
            name_kh: ""
        }
    },
    mounted() {
        // this.changeLanguage();
        this.loadLocaleMessges();
        this.reportyear();
    },
    methods: {
        changeLanguage(obj) {
            this.language = obj.target.value
            localStorage.setItem('language', obj.target.value)
            this.loadLocaleMessges()
        },
        loadLocaleMessges() {
            console.log("here");
            const data = db.collection("Transaltion").doc(this.language.toUpperCase())
            data.get().then((doc) => {
                if (!doc.exists) return;

                this.header_1 = doc.data().aboutus.header_1;
                this.line_2 = doc.data().aboutus.line_2;
                this.mission_1 = doc.data().aboutus.mission_1;
                this.mission_2 = doc.data().aboutus.mission_2;
                this.our_services = doc.data().aboutus.our_services;
                this.our_services_1 = doc.data().aboutus.our_services_1;
                this.vision = doc.data().aboutus.vision;
                this.vision_1 = doc.data().aboutus.vision_1;
                this.report = doc.data().aboutus.report;
                // console.log("ข้อมูล", doc.data().header.home);
                // console.log("ข้อมูล???", doc.data().home.mission1);
            });
        },
        reportyear() {
            console.log("report");
            const data = db.collection("Report").doc('reportyear');
        
            data.get().then((doc) => {
                console.log("!!!!!!")
                if (!doc.exists) return;
                console.log(typeof doc.data().link_report_en);
                console.log(this.link_en);
                console.log(typeof this.link_en);
                this.link_en = doc.data().link_report_en;
                this.name_en = doc.data().name_report_en;
                this.link_kh = doc.data().link_report_kh;
                this.name_kh = doc.data().name_report_kh;
                console.log("อยู่นี้");
                console.log("set param");
                
            })
                .catch((error) => {
                    console.error("Error fetching document: ", error);
                });
        }




    }
}

</script>

<style>

</style>