<template>
    <footer class="section footer-area">
        <!-- Footer Top -->
        <div class="footer-top ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title text-uppercase mb-2">{{ products }}</h3>
                            <ul>
                                <li class="py-2"><a class="text-black-50" href="/product">{{ motorcycle }}</a></li>
                                <li class="py-2"><a class="text-black-50" href="/product">{{ threewheel }}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title text-uppercase mb-2">{{ payment_channels }}</h3>
                             <div style="height:10px"></div>
                            <ul>
                                <img src="assets/img/wing_icon.png" alt="wing icon" width="60" height="160"><br>
                                <div style="height:20px"></div>
                                <img src="assets/img/e-money.png" alt="e-money icon" width="50" height="100"><br>
                                <img src="assets/img/ACELDA.png" alt="ACELDA icon" width="80" height="160"><br>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title text-uppercase mb-2">{{ call_center }}</h3>
                            <ul>
                                <li class="py-2"><a class="text-black-50">{{ service_from }}<br>{{ day_work1 }}<br>{{ day_work2 }}</a></li>
                                <li class="py-2"><a class="text-black-50" href="tel:+499123456789" ><i class="fas fa-phone-alt"></i> +855 (0)10 22 50 44</a></li>
                            </ul><br>
                            <h3 class="footer-title text-uppercase mb-2">{{ contactus }}</h3>
                            <ul>
                                <li class="py-2"><a class="text-black-50"><i class="fas fa-home"></i>   {{ branch_1 }}<br> {{ addr_branch_1 }}<br>{{ addr_branch_2 }}<br>{{addr_branch_3 }}<br>{{ addr_branch_4 }}</a></li>
                                <li class="py-2"><a class="text-black-50" href="tel:+85510225044" ><i class="fas fa-phone-alt"></i> {{ phone_1 }}</a></li><br>
                            </ul><br>
                          
                            <ul>
                                <li class="py-2"><a class="text-black-50"><i class="fas fa-home"></i>   {{branch_2}}<br>{{addr_branch_5}}<br>{{addr_branch_6}}<br>{{addr_branch_7}}</a></li>
                                <li class="py-2"><a class="text-black-50" href="tel:+85570255044" ><i class="fas fa-phone-alt"></i> {{ phone_2 }}</a></li><br>
                            </ul>
                            <ul>
                                <li class="py-2"><a class="text-black-50"><i class="fas fa-home"></i>   {{ branch_3 }}<br> {{ addr_branch_8 }}<br>{{ addr_branch_9 }}<br>{{addr_branch_10 }}<br></a></li>
                                <li class="py-2"><a class="text-black-50" href="tel:+85507225044" ><i class="fas fa-phone-alt"></i> {{ phone_3 }}</a></li>
                                <li class="py-2"><a class="text-black-50" href="tel:+855061225044" ><i class="fas fa-phone-alt"></i> {{ phone_4 }}</a></li><br>

                            </ul><br>

                              <ul>
                                <li class="py-2"><a class="text-black-50" href="mailto:rptnrafco@gmail.com"><i class="fas fa-envelope"></i>   {{email}} : rptnrafco@gmail.com</a></li><br>
                            </ul>

                              <ul>
                                <li class="py-2"><a class="text-black-50" href="https://rafcocambodia.com/" ><i class="fas fa-globe"></i>   {{ website }} : https://rafcocambodia.com/</a></li><br>
                            </ul>

                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title text-uppercase mb-2">{{follow_us}}</h3>
                            <p class="mb-2">{{follow}}</p>
                            <!-- Social Icons -->
                            <ul class="social-icons list-inline pt-2">
                                <li class="list-inline-item px-1"><a href="https://www.facebook.com/RAFCOCAMBODIA/" target="_blank"><i class="fab fa-facebook"></i></a></li>
                                <li class="list-inline-item px-1"><a href="https://t.me/rafcocombodia" target="_blank"><i class="fab fa-telegram"></i></a></li>
                                <li class="list-inline-item px-1"><a href="https://www.instagram.com/rafcocambodia/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer Bottom -->
        <div class="footer-bottom bg-grey">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Copyright Area -->
                        <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                            <!-- Copyright Left -->
                            <div class="copyright-left">&copy; Copyrights 2021 RAFCO CAMBODIA</div>
                            <!-- Copyright Right -->
                            <!-- <div class="copyright-right">Made with <i class="fas fa-heart"></i> By <a href="#">Themeland</a></div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { db } from '../../../src/firebase-config.js'

export default {
    data() {
        return {
            language: localStorage.getItem('language') || 'en',
            products: "",
            motorcycle: "",
            threewheel:"",
            payment_channels:"",
            call_center:"",
            service_from:"",
            day_work1:"",
            day_work2:"",
            contactus:"",
            branch_1:"",
            addr_branch_1:"",
            addr_branch_2:"",
            addr_branch_3:"",
            addr_branch_4:"",
            phone_1:"",
            branch_2:"",
            addr_branch_5:"",
            addr_branch_6:"",
            addr_branch_7:"",
            phone_2:"",
            branch_3:"",
            addr_branch_8:"",
            addr_branch_9:"",
            addr_branch_10:"",
            phone_3:"",
            phone_4:"",
            email:"",
            website:"",
            follow_us:"",
            follow:""

        }
    },
    mounted(){
        this.loadLocaleMessges();
        // this.changeLanguage();
    },
    methods:{
        changeLanguage(obj){
            this.language = obj.target.value
            localStorage.setItem('language',obj.target.value)
            this.loadLocaleMessges()
        },
        loadLocaleMessges() {
            console.log("here");
            const data = db.collection("Transaltion").doc(this.language.toUpperCase())
            data.get().then((doc) => {
                if (!doc.exists) return;
    
                this.products = doc.data().footer.products;
                this.motorcycle = doc.data().footer.motorcycle;
                this.threewheel = doc.data().footer.threewheel;
                this.payment_channels = doc.data().footer.payment_channels;
                this.call_center = doc.data().footer.call_center;
                this.service_from = doc.data().footer.service_from;
                this.day_work1 = doc.data().footer.day_work1;
                this.day_work2 = doc.data().footer.day_work2;
                this.contactus = doc.data().footer.contactus;
                this.branch_1 = doc.data().footer.branch_1;
                this.addr_branch_1 = doc.data().footer.addr_branch_1;
                this.addr_branch_2 = doc.data().footer.addr_branch_2;
                this.addr_branch_3 = doc.data().footer.addr_branch_3;
                this.addr_branch_4 = doc.data().footer.addr_branch_4;
                this.phone_1 = doc.data().footer.phone_1;
                this.branch_2 = doc.data().footer.branch_2;
                this.addr_branch_5 = doc.data().footer.addr_branch_5;
                this.addr_branch_6 = doc.data().footer.addr_branch_6;
                this.addr_branch_7 = doc.data().footer.addr_branch_7;
                this.phone_2 = doc.data().footer.phone_2;
                this.branch_3 = doc.data().footer.branch_3;
                this.addr_branch_8 = doc.data().footer.addr_branch_8;
                this.addr_branch_9 = doc.data().footer.addr_branch_9;
                this.addr_branch_10 = doc.data().footer.addr_branch_10;
                this.phone_3 = doc.data().footer.phone_3;
                this.phone_4 = doc.data().footer.phone_4;
                this.email = doc.data().footer.email;
                this.website = doc.data().footer.website;
                this.follow_us = doc.data().footer.follow_us;
                this.follow = doc.data().footer.follow;
         
        
            });
        }
    }
}
</script>

<style>

</style>