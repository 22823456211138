<template>
    <section class="section map-area">
        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3881.931175541492!2d103.8695278!3d13.354555600000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDIxJzE2LjQiTiAxMDPCsDUyJzEwLjMiRQ!5e0!3m2!1sth!2sth!4v1676868105686!5m2!1sth!2sth" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <!-- <iframe src="https://goo.gl/maps/CbNCRkbT9KHUMYnN8" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.596666220624!2d-0.16124461362595294!3d51.46556134684942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487605a25375dfb7%3A0xe0d9fa09dcf932a8!2s15%20Theatre%20St%2C%20Battersea%2C%20London%20SW11%205ND%2C%20UK!5e0!3m2!1sen!2sbd!4v1567427969685!5m2!1sen!2sbd" width="100" height="100" style="border:0;" allowfullscreen=""></iframe> -->
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>