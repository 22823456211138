<template>
    <section class="section our-goal ptb_50">
    <br>
    <br>
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-12 col-lg-6">
                    <div class="goal-content section-heading text-center text-lg-left pr-md-4 mb-0">
                        <h3>{{battambang}}</h3><br>
                            <ul>
                                <li class="py-2"><a class="text-black-50"><i class="fas fa-home"></i>   {{addr_5}}<br>{{addr_6}}<br>{{addr_7}}</a></li>
                                <li class="py-2"><a class="text-black-50" href="tel:+85570255044" ><i class="fas fa-phone-alt"></i> +855 (0)70 25 50 44</a></li><br>
                            </ul>

                    </div>
                </div>
         
            </div>
        </div>

    </section>
</template>

<script>
import { db } from '../../../src/firebase-config.js'

export default {
    data() {
        return {
            language: localStorage.getItem('language') || 'en',
            battambang: "",
            addr_5:"",
            addr_6:"",
            addr_7:"",
    
        }
    },
    mounted(){
        this.loadLocaleMessges();
        // this.changeLanguage();
    },
    methods:{
        changeLanguage(obj){
            this.language = obj.target.value
            localStorage.setItem('language', obj.target.value)
            this.loadLocaleMessges()
        },
        loadLocaleMessges() {
            console.log("here");
            const data = db.collection("Transaltion").doc(this.language.toUpperCase())
            data.get().then((doc) => {
                if (!doc.exists) return;
    
                this.battambang = doc.data().contact.battambang;
                this.addr_5 = doc.data().contact.addr_5;
                this.addr_6 = doc.data().contact.addr_6;
                this.addr_7 = doc.data().contact.addr_7;
               

                // console.log("ข้อมูล", doc.data().header.home);
                // console.log("ข้อมูล???", doc.data().home.mission1);
        
            });
        }
    }
}
</script>

<style>

</style>