
<template>
    <header id="header">
        <!-- Navbar -->
        <nav data-aos="zoom-out" data-aos-delay="800" class="navbar navbar-expand">
            <div class="container header">
                <!-- Navbar Brand-->
                <a class="navbar-brand" href="/">
                    <img class="navbar-brand-regular" width="60%" src="assets/img/logo-white.png" alt="brand-logo">
                    <img class="navbar-brand-sticky" width="60%" src="assets/img/logo.png" alt="sticky brand-logo">
                </a>
                <div class="ml-auto"></div>
                <!-- Navbar -->
                <ul class="navbar-nav items">
                    <li class="nav-item">
                        <!-- <h5>{{ home }}</h5> -->
                        <a href="/" class="nav-link"> {{ home }}</a>
                    </li>
                    <li class="nav-item">
                        <a href="/product" class="nav-link">{{ products }}</a>
                    </li>
                    <li class="nav-item">
                        <a href="/about" class="nav-link">{{ aboutus }}</a>
                    </li>
                    <li class="nav-item">
                        <a href="/contact" class="nav-link">{{ contactus }}</a>
                    </li>
                </ul>

                <!-- Navbar Icons -->
                <ul class="navbar-nav icons">
                    <!-- <li class="nav-item">
                        <a href="#" class="nav-link" data-toggle="modal" data-target="#search">
                            <i class="fas fa-search"></i>
                        </a>
                    </li> -->
                    <li class="nav-item social">
                        <a href="https://www.facebook.com/RAFCOCAMBODIA/" target="_blank" class="nav-link"><i
                                class="fab fa-facebook-square fa-lg"></i></a>
                    </li>
                    <!-- <li class="nav-item social">
                        <a href="#" class="nav-link"><i class="fab fa-twitter"></i></a>
                    </li> -->
                </ul>

                <!-- icon localeSwitcher -->
                <select v-model="language" @change="changeLanguage">
                    <option value="en">English</option>
                    <option value="kh">កម្ពុជា</option>
                </select>

                <!-- <select v-model="$i18n.locale" @change="changeLanguage">
                    <option value="en">English</option>
                    <option value="fr">កម្ពុជា</option>
                </select> -->

                <!-- Navbar Toggler -->
                <ul class="navbar-nav toggle">
                    <li class="nav-item">
                        <a href="#" class="nav-link" data-toggle="modal" data-target="#menu">
                            <svg class="svg-inline--fa fa-bars fa-w-14 toggle-icon m-0" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
                        </a>
                    </li>
                </ul>

                <!-- Navbar Action Button -->
                <!-- <ul class="navbar-nav action">
                    <li class="nav-item ml-3">
                        <a href="#" class="btn ml-lg-auto btn-bordered-white"><i class="fas fa-paper-plane contact-icon mr-md-2"></i>Contact Us</a>
                    </li>
                </ul> -->
            </div>
        </nav>
    </header>
</template>

<script>
import { db } from '../../../src/firebase-config.js'
export default {
    data() {
        return {
            language: localStorage.getItem('language') || 'en',
            home: "",
            products: "",
            aboutus: "",
            contactus: ""
        }
    },
    mounted() {
        this.loadLocaleMessges();
        // this.changeLanguage();
    },
    methods: {
        changeLanguage(obj) {
            this.language = obj.target.value
            localStorage.setItem('language', obj.target.value)
            this.loadLocaleMessges()
            window.location.reload();
        },
        loadLocaleMessges() {
            const data = db.collection("Transaltion").doc(this.language.toUpperCase())
            data.get().then((doc) => {
                if (!doc.exists) return;
                this.home = doc.data().header.home;
                this.products = doc.data().header.products;
                this.aboutus = doc.data().header.aboutus;
                this.contactus = doc.data().header.contactus;
            

            });
        }
    }
}

</script>

<style>

</style>