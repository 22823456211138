<template>
    <section class="section brand-partner ptb_230">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-7">
                    <!-- Section Heading -->
                    <div class="section-heading text-center mb-2">
                        <!-- <h5>cvcbc</h5> -->
                        <h2 >{{ photo_service }}</h2>
                     
                        <!-- <h2>{{doc.data().home.mission1}}</h2> -->
                        <!-- <p class="d-none d-sm-block mt-4">{{$t('home.economic')}}</p> -->
                        <p class="d-none d-sm-block mt-4">{{ economic }}</p>

                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12">
                    <!-- Brand Partner Wrapper -->
                    <ul class="brand-partner-wrapper list-inline text-center">
                        <!-- Single Partner -->
                        <li class="single-partner list-inline-item p-lg-3">
                            <img src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1635481673sm-1.jpg" alt="">
                        </li>
                        <!-- Single Partner -->
                        <li class="single-partner list-inline-item p-lg-3">
                            <img src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1635481789sm-4.jpg" alt="">
                        </li>
                        <!-- Single Partner -->
                        <li class="single-partner list-inline-item p-lg-3">
                            <img src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1635481803sm-5.jpg" alt="">
                        </li>
                        <!-- Single Partner -->
                        <li class="single-partner list-inline-item p-lg-3">
                            <img src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1635481821sm-2.jpg" alt="">
                        </li>
                        <!-- Single Partner -->
                        <li class="single-partner list-inline-item p-lg-3">
                            <img src="https://s3-ap-southeast-1.amazonaws.com/storage-pkg/icon/icon1635481868sm-3.jpg" alt="">
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { db } from '../../../src/firebase-config.js'

export default {
    data() {
        return {
            language: localStorage.getItem('language') || 'en',
            photo_service: "",
            economic: ""
        }
    },
    mounted(){
        this.loadLocaleMessges();
        // this.changeLanguage();
    },
    methods:{
        changeLanguage(obj){
            this.language = obj.target.value
            localStorage.setItem('language',obj.target.value)
            this.loadLocaleMessges()
        },
        loadLocaleMessges() {
            console.log("here");
            const data = db.collection("Transaltion").doc(this.language.toUpperCase())
            data.get().then((doc) => {
                if (!doc.exists) return;
    
                this.photo_service = doc.data().home.photo_service;
                this.economic = doc.data().home.economic;

       
        
            });
        }
    }
}

</script>

<style>

</style>