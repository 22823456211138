import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';


var config = {
  apiKey: "AIzaSyAFu2plSttiLtvs46wZCZqpDbHXjiHVcIM",
  authDomain: "website-rafco-info.firebaseapp.com",
  projectId: "website-rafco-info",
  storageBucket: "website-rafco-info.appspot.com",
  messagingSenderId: "999739023158",
  appId: "1:999739023158:web:f668e615c338b63e111d97",
  measurementId: "G-PNCMYZFNZH"
}
const firebaseApp = firebase.initializeApp(config);
// const firestore = firebase.firestore();

export default firebaseApp.firestore();
export var db = firebase.firestore();
export var storage = firebase.storage();
export var firestore = firebase.firestore();
