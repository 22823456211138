<template>
  <div>
    <ScrollupSection />
    <div class="main overflow-hidden">
      <HeaderSection />
      <!-- <BreadcrumbSection /> -->
      <AddressBranch />
      <MapSection />
      <AddressBranch2 />
      <MapSection2 />
      <AddressBranch3 />
      <MapSection3 />
      <ContactSection />
      <FooterSection />
      <ModalSearchSection />
      <ModalMenuSection />
    </div>
  </div>
</template>

<script>
import ScrollupSection from '../../Scrollup/Scrollup'
import HeaderSection from '../../Header/HeaderOne'
import AddressBranch from '../../AddressBranch/AddressBranch1'
import AddressBranch2 from '../../AddressBranch/AddressBranch2'
import AddressBranch3 from '../../AddressBranch/AddressBranch3'
// import BreadcrumbSection from '../../Breadcrumb/Contact'
import ContactSection from '../../Contact/ContactThree'
import MapSection from '../../GoogleMap/Map'
import MapSection2 from '../../GoogleMap/Map_branch2'
import MapSection3 from '../../GoogleMap/Map_branch3'
import FooterSection from '../../Footer/FooterOne'
import ModalSearchSection from '../../Modal/ModalSearch/ModalSearch'
import ModalMenuSection from '../../Modal/ModalMenu/ModalMenu'

export default {
  name: 'Contact',
  components: {
    ScrollupSection,
    HeaderSection,
    AddressBranch,
    AddressBranch2,
    AddressBranch3,
    // BreadcrumbSection,
    ContactSection,
    MapSection,
    MapSection2,
    MapSection3,

    FooterSection,
    ModalSearchSection,
    ModalMenuSection
  }
}
</script>

<style>

</style>