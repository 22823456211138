<template>
    <section class="section map-area">
        <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d7771.7865511441905!2d103.17574500000002!3d13.105947!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDA2JzIxLjQiTiAxMDPCsDEwJzMyLjciRQ!5e0!3m2!1sth!2sth!4v1706148268468!5m2!1sth!2sth" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.1587419565067!2d103.21102561460494!3d13.089124090778842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3105491fac97de17%3A0xd3f703e75d74bcf2!2sRAFCO!5e0!3m2!1sth!2sde!4v1635910726842!5m2!1sth!2sde" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.596666220624!2d-0.16124461362595294!3d51.46556134684942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487605a25375dfb7%3A0xe0d9fa09dcf932a8!2s15%20Theatre%20St%2C%20Battersea%2C%20London%20SW11%205ND%2C%20UK!5e0!3m2!1sen!2sbd!4v1567427969685!5m2!1sen!2sbd" width="100" height="100" style="border:0;" allowfullscreen=""></iframe> -->
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>