<template>
    <section class="section our-goal ptb_50">
    <br>
    <br>
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-12 col-lg-6">
                    <div class="goal-content section-heading text-center text-lg-left pr-md-4 mb-0">
                        <h3>{{siemreap}}</h3><br>
                            <ul>
                                <li class="py-2"><a class="text-black-50"><i class="fas fa-home"></i>   {{addr_8}}<br>{{addr_9}}<br>{{addr_10}}</a></li>
                                <li class="py-2"><a class="text-black-50" href="tel:+85507225044" ><i class="fas fa-phone-alt"></i> +855 (0) 72 25 044 </a></li>
                                <li class="py-2"><a class="text-black-50" href="tel:+855061225044" ><i class="fas fa-phone-alt"></i> +855 (0) 61 22 50 44</a></li><br>
                            </ul>

                    </div>
                </div>
         
            </div>
        </div>

    </section>
</template>

<script>
import { db } from '../../../src/firebase-config.js'

export default {
    data() {
        return {
            language: localStorage.getItem('language') || 'en',
            siemreap: "",
            addr_8:"",
            addr_9:"",
            addr_10:"",
    
        }
    },
    mounted(){
        this.loadLocaleMessges();
        // this.changeLanguage();
    },
    methods:{
        changeLanguage(obj){
            this.language = obj.target.value
            localStorage.setItem('language', obj.target.value)
            this.loadLocaleMessges()
        },
        loadLocaleMessges() {
            console.log("here");
            const data = db.collection("Transaltion").doc(this.language.toUpperCase())
            
            data.get().then((doc) => {
                if (!doc.exists) return;
    
                this.siemreap = doc.data().contact.siemreap;
                this.addr_8 = doc.data().contact.addr_8;
                this.addr_9 = doc.data().contact.addr_9;
                this.addr_7 = doc.data().contact.addr_10;
               

           
        
            });
        }
    }
}
</script>

<style>

</style>