<template>
  <div class="top">
    <mdb-carousel :interval="6000" slide :items="images" indicators></mdb-carousel>
  </div>
</template>

<style scoped>
/* 
    .items{
    height: 100px !important;
    max-width: 100% !important; 
    } */

.top {
  margin-top: 145px;
} 
</style>

<script>
import db from '../../../src/firebase-config.js'
import { mdbCarousel } from "mdbvue";

export default {
  name: "CarouselPage",
  components: {
    mdbCarousel
  },
  data() {
    return {
      images: []
    };
  },
  mounted() {
    db.collection("Banner").get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        this.images.push(
          {
          img: true,
          src: doc.data().banner1
        },
        {
          img: true,
          src: doc.data().banner2
        },
        {
          img: true,
          src: doc.data().banner3
        }
        );
      });
    });
    console.log("?????",this.images)
  }
};
</script>
