<template>
    <section class="section our-goal ptb_190">
    <br>
    <br>
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-12 col-lg-6">
                    <div class="goal-content section-heading text-center text-lg-left pr-md-4 mb-0">
                        <h3>{{phnompenh}}</h3><br>
                            <ul>
                                <li class="py-2"><a class="text-black-50"><i class="fas fa-home"></i>   {{phnompenh}}<br> {{addr_1}}<br>{{addr_2}}<br>{{addr_3}}<br>{{addr_4}}</a></li>
                                <li class="py-2"><a class="text-black-50" href="tel:+85510225044" ><i class="fas fa-phone-alt"></i> +855 (0)10 22 50 44</a></li><br>
                            </ul>

                    </div>
                </div>
         
            </div>
        </div>

    </section>
</template>

<script>
import { db } from '../../../src/firebase-config.js'

export default {
    data() {
        return {
            language: localStorage.getItem('language') || 'en',
            phnompenh: "",
            addr_1:"",
            addr_2:"",
            addr_3:"",
            addr_4:""
        }
    },
    mounted(){
        this.loadLocaleMessges();
        // this.changeLanguage();
    },
    methods:{
        changeLanguage(obj){
            this.language = obj.target.value
            localStorage.setItem('language', obj.target.value)
            this.loadLocaleMessges()
        },
        loadLocaleMessges() {
            const data = db.collection("Transaltion").doc(this.language.toUpperCase())
            data.get().then((doc) => {
                if (!doc.exists) return;
    
                this.phnompenh = doc.data().contact.phnompenh;
                this.addr_1 = doc.data().contact.addr_1;
                this.addr_2 = doc.data().contact.addr_2;
                this.addr_3 = doc.data().contact.addr_3;
                this.addr_4 = doc.data().contact.addr_4;

        
            });
        }
    }
}

</script>

<style>

</style>